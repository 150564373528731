import React from 'react';
import styles from './Learn.css';

const Learn = () => {
  return (
    <>
      <section className={styles.Learn}>
        <h1>Learn</h1>
      </section>
    </>
  );
};

export default Learn;

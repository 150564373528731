// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2RQeyGnea47u0snB6sfhtr {\n  margin-right: 25%;\n  margin-top: 8%;\n  width: 75%;\n}\n  ._2RQeyGnea47u0snB6sfhtr ._2AwgLRE9X3lczCBg2-F8NQ {\n    display: flex;\n    justify-content: center;\n\n    width: auto;\n  }\n  ._2RQeyGnea47u0snB6sfhtr img {\n    width: 25%;\n  }\n", "",{"version":3,"sources":["Learn.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,UAAU;AAUZ;EATE;IACE,aAAa;IACb,uBAAuB;;IAEvB,WAAW;EACb;EACA;IACE,UAAU;EACZ","file":"Learn.css","sourcesContent":[".Learn {\n  margin-right: 25%;\n  margin-top: 8%;\n  width: 75%;\n  .Image {\n    display: flex;\n    justify-content: center;\n\n    width: auto;\n  }\n  img {\n    width: 25%;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"Learn": "_2RQeyGnea47u0snB6sfhtr",
	"Image": "_2AwgLRE9X3lczCBg2-F8NQ"
};
module.exports = exports;

import React from 'react';
import styles from './Projects.css';

const Projects = () => {
  return (
    <>
      <section className={styles.Projects}>
        <h1>Projects</h1>
      </section>
    </>
  );
};

export default Projects;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2NxK7wz0DWDyfb6CUUzA3n {\n  margin-right: 25%;\n  margin-top: 8%;\n  width: 75%;\n}\n  ._2NxK7wz0DWDyfb6CUUzA3n ._16jmBuYpEOGvvdDnplHHXW {\n    display: flex;\n    justify-content: center;\n\n    width: auto;\n  }\n  ._2NxK7wz0DWDyfb6CUUzA3n img {\n    width: 25%;\n  }\n", "",{"version":3,"sources":["Projects.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,UAAU;AAUZ;EATE;IACE,aAAa;IACb,uBAAuB;;IAEvB,WAAW;EACb;EACA;IACE,UAAU;EACZ","file":"Projects.css","sourcesContent":[".Projects {\n  margin-right: 25%;\n  margin-top: 8%;\n  width: 75%;\n  .Image {\n    display: flex;\n    justify-content: center;\n\n    width: auto;\n  }\n  img {\n    width: 25%;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"Projects": "_2NxK7wz0DWDyfb6CUUzA3n",
	"Image": "_16jmBuYpEOGvvdDnplHHXW"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1jlUQaGqnzuHphlCU7DciD {\n  margin-right: 25%;\n  margin-top: 8%;\n  width: 75%;\n}\n  ._1jlUQaGqnzuHphlCU7DciD ._3KM6VriNugDWW3-HNk_10E {\n    display: flex;\n    justify-content: center;\n\n    width: auto;\n  }\n  ._1jlUQaGqnzuHphlCU7DciD img {\n    width: 25%;\n  }\n", "",{"version":3,"sources":["Hardware.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,UAAU;AAUZ;EATE;IACE,aAAa;IACb,uBAAuB;;IAEvB,WAAW;EACb;EACA;IACE,UAAU;EACZ","file":"Hardware.css","sourcesContent":[".Hardware {\n  margin-right: 25%;\n  margin-top: 8%;\n  width: 75%;\n  .Image {\n    display: flex;\n    justify-content: center;\n\n    width: auto;\n  }\n  img {\n    width: 25%;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"Hardware": "_1jlUQaGqnzuHphlCU7DciD",
	"Image": "_3KM6VriNugDWW3-HNk_10E"
};
module.exports = exports;

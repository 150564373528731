import React from 'react';
import styles from './Hardware.css';

const Hardware = () => {
  return (
    <>
      <section className={styles.Hardware}>
        <h1>Hardware</h1>
      </section>
    </>
  );
};

export default Hardware;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3iFfUx5ef-DqN7x3pzNb0P {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  margin: auto;\n  flex-direction: column;\n  border: 1px dashed #d3455b;\n  border-radius: 1rem;\n  margin: 1rem;\n  padding: 1rem;\n\n\n}\n\n  ._3iFfUx5ef-DqN7x3pzNb0P ._3DZ4MTKR4n4jV-VajW3y9p {\n    margin-top: -1rem;\n  }\n\n  ._3iFfUx5ef-DqN7x3pzNb0P ._7ntQyO84vIwWAYvrPP4Zc {\n    margin-top: -0.5rem;\n  }\n\n  ._3iFfUx5ef-DqN7x3pzNb0P ._2L4RP4InFmzBZ0JYm_WbEP {\n    position: relative;\n    padding-bottom: 56.25%;\n  }\n\n  /* 16:9 */\n\n  ._3iFfUx5ef-DqN7x3pzNb0P ._2L4RP4InFmzBZ0JYm_WbEP iframe {\n    position: relative;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    }\n\n  ._3iFfUx5ef-DqN7x3pzNb0P ._2L4RP4InFmzBZ0JYm_WbEP {\n    margin-top: 0.5rem;\n\n\n}\n", "",{"version":3,"sources":["AppsList.css","<no source>"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,0BAA0B;EAC1B,mBAAmB;EACnB,YAAY;EACZ,aAAa;;;AAyBf;;EAvBE;IACE,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;EACrB;;EACA;IACE,kBAAkB;IAClB,sBAAsB;EAWxB;;EAX0B,SAAS;;EAEjC;IACA,kBAAkB;IAClB,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ;;EC7BJ;ID8BI;;;CC9BJ","file":"AppsList.css","sourcesContent":[".AppsList {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  margin: auto;\n  flex-direction: column;\n  border: 1px dashed #d3455b;\n  border-radius: 1rem;\n  margin: 1rem;\n  padding: 1rem;\n\n  .Stack {\n    margin-top: -1rem;\n  }\n\n  .Links {\n    margin-top: -0.5rem;\n  }\n  .Video {\n    position: relative;\n    padding-bottom: 56.25%; /* 16:9 */\n    \n    iframe {\n    position: relative;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    }\n    margin-top: 0.5rem;\n  }\n\n\n}\n",null]}]);
// Exports
exports.locals = {
	"AppsList": "_3iFfUx5ef-DqN7x3pzNb0P",
	"Stack": "_3DZ4MTKR4n4jV-VajW3y9p",
	"Links": "_7ntQyO84vIwWAYvrPP4Zc",
	"Video": "_2L4RP4InFmzBZ0JYm_WbEP"
};
module.exports = exports;
